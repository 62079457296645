import Base from './Base.js';

export default function WorkOrderCostStatusCreator(i18n) {
  class WorkOrderCostStatus extends Base {}

  WorkOrderCostStatus.initEnum({
    DRAFT: {
      value: 'DRAFT',
      display: i18n.global.t('enums.WorkOrderCostStatus.draft'),
    },
    PENDING: {
      value: 'PENDING',
      display: i18n.global.t('enums.WorkOrderCostStatus.pending'),
    },
    APPROVED: {
      value: 'APPROVED',
      display: i18n.global.t('enums.WorkOrderCostStatus.approved'),
    },
    QUERIED: {
      value: 'QUERIED',
      display: i18n.global.t('enums.WorkOrderCostStatus.queried'),
    },
    REJECTED: {
      value: 'REJECTED',
      display: i18n.global.t('enums.WorkOrderCostStatus.rejected'),
    },
    // CORE-1804 todo: Kind of gross, perhaps this should be a proper status?
    // Used as a pseudo-status for draft lines when WO billing status is submitted
    SUBMITTED: {
      value: 'SUBMITTED',
      display: i18n.global.t('enums.WorkOrderCostStatus.submitted'),
    },
  });

  return WorkOrderCostStatus;
}
