import Base from './Base.js';

export default function BudgetSiteSelectionCreator(i18n) {
  class BudgetSiteSelectionType extends Base {}

  BudgetSiteSelectionType.initEnum({
    ALL: {
      value: 'ALL',
      display: i18n.global.t('enums.BudgetSiteSelectionType.all'),
    },
    TAG: {
      value: 'TAG',
      display: i18n.global.t('enums.BudgetSiteSelectionType.tag'),
    },
    SITE: {
      value: 'SITE',
      display: i18n.global.t('enums.BudgetSiteSelectionType.site'),
    },
  });

  return BudgetSiteSelectionType;
}
