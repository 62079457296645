<template>
  <div class="custom-dropdown">
    <Multiselect
      v-bind="extraConfig"
      :id="id"
      ref="multiselect"
      v-model="selectedOptions"
      :class="{
        'create-option': showCreateOption,
        'is-invalid': invalid
      }"
      :allow-empty="allowEmpty"
      :track-by="valueField"
      :options="options"
      :searchable="search"
      :multiple="multiple"
      :close-on-select="closeOnSelect"
      :hide-selected="hideSelected"
      :show-labels="false"
      :custom-label="getName"
      :disabled="disabled"
      :loading="isLoading"
      :max="options.length && multiple === true ? options.length : undefined"
      v-on="listeners"
      @close="() => $emit('close')"
      @remove="() => $emit('remove')"
    >
      <template v-if="showCreateOption" #afterList>
        <li
          ref="createOption"
          class="multiselect__element create-new-option"
          @click="() => {
            $refs.multiselect.deactivate();
            onCreateOption();
          }"
        >
          <div class="create-icon-container">
            <font-awesome-icon :icon="['far', 'plus']" />
          </div>
          <div class="create-text-container">
            {{ createOptionText }}
          </div>
        </li>
      </template>
      <template #placeholder>
        <span :key="placeholder" class="font-italic">{{ placeholder }}</span>
      </template>
      <template #option="{option}">
        <div
          v-if="multiple"
          class="custom-dropdown__multi"
          :style="option.isGlobalOption ? 'font-style: italic;' : ''"
        >
          <span :key="getName(option)">{{ getName(option) }}</span>
        </div>
        <li
          v-if="!multiple"
          :style="option.isGlobalOption ? 'font-style: italic;' : ''"
          class="multiselect__element"
        >
          <span :key="getName(option)">{{ getName(option) }}</span>
        </li>
      </template>
      <template v-if="ajaxSearch" #noResult>
        {{ $t('common.notFound') }}
      </template>
      <template v-if="options.length" #maxElements>
        {{ $t('common.forms.noOptionsLeft') }}
      </template>
      <template v-if="$refs.multiselect" #singleLabel="{option}">
        <span :key="JSON.stringify(option)">
          {{ $refs.multiselect.currentOptionLabel }}
        </span>
      </template>
    </Multiselect>
  </div>
</template>
<script>
import { Multiselect } from 'vue-multiselect';

export default {
  name: 'CustomDropdown',
  components: {
    Multiselect,
  },
  model: {
    prop: 'value',
    event: 'selected',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    ajaxSearch: {
      type: Function,
      default: null,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialOptions: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: null,
    },
    search: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, String, Number, Boolean],
      default: null,
    },
    valueField: {
      // key to search options
      type: [Number, String],
      default: 'id',
    },
    textField: {
      type: [String, Function],
      default: 'name',
    },
    reposition: {
      type: Boolean,
      default: true,
    },
    scrollingParentSelector: {
      type: String,
      default: '.modal-dialog-scrollable .modal-body',
    },
    onCreateOption: {
      type: Function,
      default: null,
    },
    createOptionText: {
      type: String,
      default: 'Create',
    },
    showCreateOption: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      options: this.initialOptions,
      isLoading: false,
      ajaxCache: {},
      scrollableEl: null,
    };
  },
  computed: {
    selectedOptions: {
      get() {
        if (this.value === null || this.value === []) {
          return this.value;
        }

        // we always want an array so we convert to one if we aren't given one
        const arrId = Array.isArray(this.value) ? this.value : [this.value];

        const arrObj = !this.ajaxSearch
          ? arrId.map((id) =>
              this.options.find((item) => String(item[this.valueField]) === String(id)),
            )
          : arrId.map((id) => this.ajaxCache[id] ?? { [this.valueField]: id });

        return this.multiple ? arrObj : arrObj[0];
      },
      set(value) {
        if (value === null) {
          this.$emit('selected', value);
        } else {
          const arrObj = Array.isArray(value) ? value : [value];
          const arrId = arrObj.map((v) => v[this.valueField]);

          if (this.ajaxSearch) {
            arrObj.forEach((v) => {
              // cache values with their ID as the key. sometimes the actual object is under
              // the `valueField` property. e.g. { "valueField": <ref> }, so we pull it from there
              this.ajaxCache[v[this.valueField] ?? v.id] = v;
            });
          }

          this.repositionDropdown();

          this.$emit('selected', this.multiple ? arrId : arrId[0]);
        }
      },
    },
    extraConfig() {
      return this.ajaxSearch
        ? {
            initialOptions: this.initialOptions,
            internalSearch: false,
            showNoResults: true,
            showNoOptions: false,
          }
        : {
            showNoResults: false,
          };
    },
    extraEvents() {
      return this.ajaxSearch ? { 'search-change': this.asyncFind } : {};
    },
    listeners() {
      return {
        ...this.extraEvents,
        ...this.$attrs,
        open: this.onOpen,
        close: this.onClose,
        searchChange: () => {
          this.$nextTick(() => {
            this.repositionDropdown();
          })
        }
      };
    },
    closeOnSelect() {
      return !this.multiple;
    },
    hideSelected() {
      return this.multiple;
    },
  },
  watch: {
    initialOptions(value) {
      this.options = value;
    },
  },
  mounted() {
    // find the closest scrollable modal and reposition when scroll occurs
    this.scrollableEl = this.$el.closest(this.scrollingParentSelector);

    if (this.scrollableEl && this.reposition) {
      this.scrollableEl.addEventListener('scroll', this.repositionDropdown);
    }
  },
  unmounted() {
    if (this.scrollableEl && this.reposition) {
      this.scrollableEl.removeEventListener('scroll', this.repositionDropdown);
    }
  },
  methods: {
    getName(option) {
      if (typeof this.textField === 'function') {
        return this.textField(option);
      }
      return option[this.textField];
    },
    clearOptions() {
      // custom event so we can do some action when field is cleared
      this.$emit('cleared');

      this.$emit('selected', this.multiple ? [] : null);
    },
    async asyncFind(query) {
      this.isLoading = true;
      const data = await this.ajaxSearch(query);
      if (data) {
        this.options = data;
      } else {
        this.options = Object.values(this.ajaxCache);
      }
      this.isLoading = false;
    },

    onOpen() {
      this.repositionDropdown();
    },
    async repositionDropdown() {
      const ref = this.$refs.multiselect;

      if (!this.reposition || !ref) {
        return;
      }

      // first we have to set the width so that we don't get
      // a weird shrinking dropdown
      let { top, height, width, left } = this.$el.getBoundingClientRect();

      ref.$refs.list.style.width = `${width}px`;
      ref.$refs.list.style.position = 'fixed';
      ref.$refs.list.style.left = `${left}px`;

      // wait a tick so the field gets it correct position
      await this.$nextTick();

      // now we set the position so it tracks the expanding box correctly
      ({ top, height, width, left } = this.$el.getBoundingClientRect());

      ref.$refs.list.style.width = `${width}px`;
      ref.$refs.list.style.left = `${left}px`;

      if (ref.preferredOpenDirection === 'below') {
        top = top + height;

        if (this.showCreateOption) {
          this.$nextTick(() => {
            const wrapper = this.$refs.multiselect.$el.querySelector('.multiselect__content-wrapper');
            const content = wrapper.querySelector('ul.multiselect__content');
            content.style.borderBottom = '38px solid white';
            content.style.borderTop = 'none';
            this.$refs.createOption.style.top = `${top + wrapper.clientHeight - 38}px`;
            this.$refs.createOption.style.bottom = 'auto';
            this.$refs.createOption.style.width = `${width - 2}px`;
          });
        }

        ref.$refs.list.style.bottom = 'auto';
        ref.$refs.list.style.top = `${top}px`;
      } else {
        let bottom = document.body.clientHeight - top;

        if (this.showCreateOption) {
          this.$nextTick(() => {
            const wrapper = this.$refs.multiselect.$el.querySelector('.multiselect__content-wrapper');
            const content = wrapper.querySelector('ul.multiselect__content');
            content.style.borderTop = '38px solid white';
            content.style.borderBottom = 'none';
            this.$refs.createOption.style.bottom = `${bottom + wrapper.clientHeight - 38}px`;
            this.$refs.createOption.style.top = 'auto';
            this.$refs.createOption.style.width = `${width - 2}px`;
          });
        }

        ref.$refs.list.style.bottom = `${bottom}px`;
        ref.$refs.list.style.top = 'auto';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dropdown {
  width: 100%;

  :deep(.multiselect.is-invalid) {
    .multiselect__tags {
      background: rgba(255, 0, 0, 0.1);

      .multiselect__single {
        background: none;
      }
    }
  }

  :deep(.multiselect__tag) {
    font-weight: normal;
  }

  :deep(.multiselect__tag-icon) {
    font-weight: normal;
  }

  :deep(.multiselect__content-wrapper) {
    overflow: unset;
  }

  // only do if 'create option'
  :deep(.multiselect__content) {
    overflow: scroll;
    max-height: inherit;
    height: inherit;
  }

  .create-new-option {
    position: fixed;
    background: #3e9af5;
    color: white;
    display: block;
    padding: 0.375rem 0.75rem;
    min-height: calc(1.5em + 0.75rem + 2px);
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    transition: background 0.25s;

    &:hover {
      background: #1a87f3;
    }
  }

  .create-text-container {
    padding-left: 2.2rem;
    padding-top:  0.1rem;
  }

  .create-icon-container {
    position: absolute;
    height: 100%;
    width: 38px;
    left: 0;
    top: 0;
    text-align: center;
    background: #1a87f3;

    svg {
      height: 100%;
    }
  }
}
</style>
