import Base from './Base.js';

export default function CustomFieldEntityCreator(i18n) {
  class CustomFieldEntity extends Base {}

  CustomFieldEntity.initEnum({
    SITE: {
      value: 'SITE',
      display: 'Sites',
      singular: 'Site',
      permissions: ['Site_Manage'],
      hasRestrictions: true,
      endpoint: 'sites',
    },
    ASSET: {
      value: 'ASSET',
      display: 'Assets',
      singular: 'Asset',
      permissions: ['AssetSchema_Manage'],
      hasRestrictions: true,
      endpoint: 'assets',
    },
  });

  return CustomFieldEntity;
}
