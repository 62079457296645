export default {
  state: {
    /**
     * A Contractor definition
     * @typedef {Object} Contractor
     * @property {number} id -
     * @property {string} name -
     * @property {string} email -
     * @property {string} password -
     * @property {string} contractor_type -
     * @property {string} site_access -
     * @property {array} site_tag_ids -
     * @property {number[]} contractor_sites -
     * @property {number} receive_emails -
     * @property {number} receive_push_notifications -
     * @property {string} language -
     * @property {string} locale -
     * @property {string} timezone -
     * @property {date} created_at -
     * @property {date} updated_at -
     */
    contractor: () => ({
      id: null,
      name: null,
      account: null,
      address: null,
      city: null,
      county: null,
      country: null,
      telephone: null,
      postcode: null,
      default_tax_rate_id: null,
      email: null,
      po_number_prefix: null,
      po_number_next: null,
      notes: null,
      enabled: true,
      created_at: null,
      updated_at: null,
    }),
  },

  getters: {},

  actions: {
    GET_CONTRACTOR: 'GET_CONTRACTOR',
    UPDATE_CONTRACTOR: 'UPDATE_CONTRACTOR',
    CREATE_CONTRACTOR: 'CREATE_CONTRACTOR',
    DELETE_CONTRACTOR: 'DELETE_CONTRACTOR',
  },

  mutations: {
    SET_CONTRACTORS_SORT: 'SET_CONTRACTORS_SORT',
    SET_ACTIVE_CONTRACTOR: 'SET_ACTIVE_CONTRACTOR',
    SET_ACTIVE_CONTRACTOR_STATE: 'SET_ACTIVE_CONTRACTOR_STATE',
    SET_ACTIVE_CONTRACTOR_ERRORS: 'SET_ACTIVE_CONTRACTOR_ERRORS',
    SET_ACTIVE_CONTRACTOR_VALID: 'SET_ACTIVE_CONTRACTOR_VALID',
    UPDATE_ACTIVE_CONTRACTOR_MODIFIED_FIELDS: 'UPDATE_ACTIVE_CONTRACTOR_MODIFIED_FIELDS',
    CLEAR_ACTIVE_CONTRACTOR_MODIFIED_FIELDS: 'CLEAR_ACTIVE_CONTRACTOR_MODIFIED_FIELDS',

    SET_CONTRACTORS_FILTER: 'SET_CONTRACTORS_FILTER',
  },
};
