import axios from 'axios';

/**
 * Interface to the NavigationAPI endpoints
 * @class
 * @exports src/services/NavigationAPI
 */
class NavigationAPI {
  /**
   * Retrieves all the navigation items
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an array of {@link Navigation} items.
   */
  static async getNavigationItems(navigationSchemeId) {
    const response = await axios({
      method: 'GET',
      url: `navigation_scheme/${navigationSchemeId}`,
      responseType: 'json',
    });

    return response.data.data.items;
  }
}

export default NavigationAPI;
