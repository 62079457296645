class GetConfig {
  constructor(id) {
    this.id = id;
    this.fields = {};
    this.relationships = [];
    this.meta = {};
  }

  setMeta(meta) {
    this.meta = meta;

    return this;
  }

  setFields(fields) {
    this.fields = fields;

    return this;
  }

  setIncludes(includes) {
    this.relationships = includes;

    return this;
  }
}

export default GetConfig;
