<template>
  <div class="create-emergency-work-order">
    <div class="title mb-1">
      <font-awesome-icon :icon="['far', 'exclamation-triangle']" class="mr-1" />
      {{ $t('views.work-orders.WorkOrderDisplay.emergencyWorkOrder') }}
    </div>

    <template v-if="serviceSelectionRequired">
      <div class="mt-2">
        <div class="text-bold">{{ $t('views.work-orders.EmergencyWorkOrder.step1') }}</div>
        {{ $t('views.work-orders.EmergencyWorkOrder.instructionSelectService') }}
      </div>

      <b-spinner v-if="emergencyServices === null" small />
      <ServiceSelector
        v-else-if="emergencyServices.length > 0"
        class="mt-2 service-selector"
        :currency="currency"
        :value="workOrder.requested_service_id"
        :options="emergencyServices"
        :disable-title="true"
        :disable-group-filter="true"
        @input="serviceSelectorInput"
      />
      <div v-else-if="emergencyServices.length === 0" class="my-2 text-danger text-bold">
        <font-awesome-icon :icon="['fas', 'times-circle']" class="mr-1" />
        {{ $t('views.work-orders.EmergencyWorkOrder.noServicesAvailable') }}
      </div>
    </template>

    <b-button
      v-if="serviceSelectionRequired && !noneSuitableClicked"
      variant="link"
      class="p-0 mt-1 d-block btn-sm"
      @click.prevent="onNoneSuitableClick"
    >
      {{ $t('views.work-orders.EmergencyWorkOrder.noSuitableServices') }}
    </b-button>

    <b-spinner v-if="loadingProcedure" small />
    <div v-else-if="procedure" class="my-2">
      <div class="text-bold">
        <template v-if="serviceSelectionRequired">
          {{ $t('views.work-orders.EmergencyWorkOrder.step2') }}
        </template>
        <template v-else>
          {{ $t('views.work-orders.EmergencyWorkOrder.step1') }}
        </template>
      </div>
      <div>{{ $t('views.work-orders.EmergencyWorkOrder.followInstructionsBelow') }}</div>

      <div class="card mt-2 p-3">
        <div :key="procedure.procedure" class="text-pre-wrap">{{ procedure.procedure }}</div>

        <template v-if="procedure.contact_details">
          <div class="mt-2">{{ $t('views.work-orders.EmergencyWorkOrder.contactDetails') }}</div>
          <div :key="procedure.contact_details">{{ procedure.contact_details }}</div>
        </template>
      </div>
    </div>

    <b-button
      v-if="showCreateWithoutServiceButton"
      variant="secondary"
      class="mt-1 d-block btn-sm"
      @click.prevent="onCreateWithoutServiceClick"
    >
      {{ $t('views.work-orders.EmergencyWorkOrder.createWOWithoutService') }}
    </b-button>

    <template v-if="procedure && procedure.type === 'CONTRACTOR' && workOrder.requested_service_id">
      <div class="text-bold mt-3">{{ $t('views.work-orders.EmergencyWorkOrder.step3') }}</div>
      <div>{{ $t('views.work-orders.EmergencyWorkOrder.enterDetailsFromContact') }}</div>

      <div class="card px-3 mt-2">
        <b-form-group label-for="wo-edit-point_of_contact-input" class="mt-3">
          <template #label>
            <Required>
              {{ $t('views.work-orders.EmergencyWorkOrder.contactName') }}
            </Required>
            <div class="small text-muted">
              {{ $t('views.work-orders.EmergencyWorkOrder.contactNameSubText') }}
            </div>
          </template>

          <b-form-input
            id="wo-edit-point_of_contact-input"
            v-model="v$.workOrder.point_of_contact.$model"
            :state="!v$.workOrder.point_of_contact.$error && null"
            aria-describedby="wo-edit-point_of_contact-feedback"
            trim
            required
            @blur="v$.workOrder.point_of_contact.$touch()"
            @input="(value) => modifyWorkOrder('point_of_contact', value)"
          />

          <b-form-invalid-feedback
            v-if="v$.workOrder.point_of_contact.$error"
            id="wo-edit-point_of_contact-feedback"
            :state="v$.workOrder.point_of_contact.$error"
          >
            <p v-if="!v$.workOrder.point_of_contact.required">
              {{
                $t('common.forms.validation.required', {
                  field: $t('views.work-orders.EmergencyWorkOrder.contactName'),
                })
              }}
            </p>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="wo-edit-external_reference-input">
          <template #label>
            <Required>
              {{ $t('views.work-orders.EmergencyWorkOrder.jobReference') }}
            </Required>
            <div class="small text-muted">
              {{ $t('views.work-orders.EmergencyWorkOrder.jobReferenceSubText') }}
            </div>
          </template>

          <b-form-input
            id="wo-edit-external_reference-input"
            v-model="v$.workOrder.external_reference.$model"
            :state="!v$.workOrder.external_reference.$error && null"
            aria-describedby="wo-edit-external_reference-feedback"
            trim
            required
            @blur="v$.workOrder.external_reference.$touch()"
            @input="(value) => modifyWorkOrder('external_reference', value)"
          />

          <b-form-invalid-feedback
            v-if="v$.workOrder.external_reference.$error"
            id="wo-edit-external_reference-feedback"
            :state="v$.workOrder.external_reference.$error"
          >
            <p v-if="!v$.workOrder.external_reference.required">
              {{
                $t('common.forms.validation.required', {
                  field: $t('views.work-orders.EmergencyWorkOrder.jobReference'),
                })
              }}
            </p>
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </template>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BFormInvalidFeedback, BSpinner, BButton } from 'bootstrap-vue';
import { requiredIf } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import ServiceSelector from '@/ux/form/ServiceSelector.vue';
import Required from '@/ux/form/Required.vue';
import EmergencyWorkOrderAPI from '@/services/EmergencyWorkOrderAPI.js';
import WorkOrderAvailableServicesAPI from '@/services/WorkOrderAvailableServicesAPI.js';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'WorkOrderCreateEmergency',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSpinner,
    Required,
    ServiceSelector,
  },
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    },
    serviceTypeId: {
      type: Number,
      required: true,
    },
    workOrderTypeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      procedure: null,
      // when set to a requestHash value, prevent all updates until set back to null
      // at which point the requestHash watcher will be triggered
      holdReloadAt: null,
      loadingProcedure: false,
      serviceSelectionRequired: false,
      noneSuitableClicked: false,
      createWithoutService: false,
      emergencyServices: null,
    };
  },
  validations() {
    const val = {
      required: requiredIf(() => !this.createWithoutService),
    };
    return {
      workOrder: {
        external_reference: val,
        point_of_contact: val,
        requested_service_id: val,
      },
    };
  },
  computed: {
    ...mapGetters(['hasPermission']),

    requestHash() {
      return JSON.stringify({
        site: this.workOrder.site_id,
        requested_service_id: this.workOrder.requested_service_id,
        none_suitable: this.noneSuitableClicked,
      });
    },
    showCreateWithoutServiceButton() {
      return this.hasPermission('WorkOrder_OverrideEmergencyProcedures');
    },
    validity() {
      return !this.v$.$invalid;
    },
    currency() {
      return this.site?.Currency;
    },
  },
  watch: {
    requestHash() {
      if (!this.holdReloadAt) {
        this.loadProcedure();
      }
    },
    holdReloadAt(newV, oldV) {
      if (newV === null && oldV !== this.requestHash) {
        this.loadProcedure();
      }
    },
    validity: {
      handler(v) {
        this.$emit('formvalidate', v);
      },
      immediate: true,
    },
  },
  created() {
    this.modifyWorkOrder('requested_service_id', null);
    this.$emit('set-creation-permitted', false);
    this.loadProcedure();
  },
  beforeUnmount() {
    this.resetForm();
  },
  methods: {
    async loadProcedure() {
      this.loadingProcedure = true;
      const response = await EmergencyWorkOrderAPI.getProcedure(
        this.workOrder.site_id,
        this.workOrder.requested_service_id,
        this.noneSuitableClicked,
      );

      if (response.require_contractor) {
        this.serviceSelectionRequired = true;
        this.procedure = null;
        await this.loadServices();
      } else {
        this.procedure = response;
        this.$emit('set-creation-permitted', this.procedure.type === 'CONTRACTOR');
      }

      this.loadingProcedure = false;
    },
    async loadServices() {
      if (this.emergencyServices !== null) {
        return;
      }

      this.emergencyServices = await WorkOrderAvailableServicesAPI.getWorkOrderAvailableServices(
        'all',
        '',
        '',
        {
          site_id: this.site.id,
          service_type_id: this.serviceTypeId,
          work_order_type_id: this.workOrderTypeId,
          emergency: 1,
        },
      );
    },
    serviceSelectorInput(serviceId) {
      this.modifyWorkOrder('requested_service_id', serviceId);
      if (serviceId) {
        this.noneSuitableClicked = false;
        this.createWithoutService = false;
        this.$emit('set-creation-permitted', false);
      }
    },
    onNoneSuitableClick() {
      // this would trigger multiple procedure loads - we just need one
      this.holdReloadAt = this.requestHash;
      this.noneSuitableClicked = true;
      this.resetForm();
      this.holdReloadAt = null;
    },
    onCreateWithoutServiceClick() {
      this.holdReloadAt = this.requestHash;
      this.resetForm();
      this.createWithoutService = true;
      this.$emit('set-creation-permitted', true);
      this.holdReloadAt = null;
    },
    resetForm() {
      this.modifyWorkOrder('requested_service_id', null);
      this.modifyWorkOrder('point_of_contact', null);
      this.modifyWorkOrder('external_reference', null);
      this.v$.$reset();
    },
    modifyWorkOrder(field, value) {
      this.$emit('modify-work-order', field, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.create-emergency-work-order {
  border-top: 2px dotted $danger;
  border-bottom: 2px dotted $danger;
  padding: 1rem;
  margin: 1rem 0;
  background-color: #f0f0f0;
}

.title {
  color: $danger;
  font-weight: bold;
}

.service-selector {
  color: $body-color;
}
</style>
