import Base from './Base';

export default function AssetStatusCreator(i18n) {
  class AssetStatus extends Base {}

  AssetStatus.initEnum({
    DISPOSED: {
      value: 'Disposed',
      display: i18n.global.t('views.asset.status.disposed'),
    },
    IN_REPAIRS: {
      value: 'In repairs',
      display: i18n.global.t('views.asset.status.inRepairs'),
    },
    IN_STORAGE: {
      value: 'In storage',
      display: i18n.global.t('views.asset.status.inStorage'),
    },
    OUT_OF_SERVICE: {
      value: 'Out of service',
      display: i18n.global.t('views.asset.status.outOfService'),
    },
    IN_SERVICE: {
      value: 'In service',
      display: i18n.global.t('views.asset.status.inService'),
    },
  });

  return AssetStatus;
}
