<template>
  <b-modal
    ref="viewModal"
    size="xl"
    dialog-class="full-screen-modal"
    hide-footer
    scrollable
    no-close-on-backdrop
    :static="staticModal"
    @close="$emit('close')"
  >
    <template v-slot:modal-header>
      <div ref="buttons" class="w-100 d-flex justify-content-between">
        <IconButton
          data-ref="close"
          icon="times"
          icon-size="lg"
          class="text-white-50"
          :text="$t('components.attachment.AttachmentPreview.closePreview')"
          @click="$emit('close')"
        />
        <div>
          <IconButton
            v-if="!isVideo(attachment)"
            data-ref="zoom-in"
            icon="search-plus"
            icon-size="lg"
            class="text-white-50"
            :text="$t('components.attachment.AttachmentPreview.zoomIn')"
            :disabled="zoom >= 2 || loading"
            @click="zoomIn"
          />
          <IconButton
            v-if="!isVideo(attachment)"
            data-ref="zoom-out"
            icon="search-minus"
            icon-size="lg"
            class="text-white-50"
            :text="$t('components.attachment.AttachmentPreview.zoomOut')"
            :disabled="zoom <= 0.2 || loading"
            @click="zoomOut"
          />
          <IconButton
            v-if="!isVideo(attachment)"
            data-ref="rotate-anticlockwise"
            icon="undo"
            icon-size="lg"
            :disabled="loading"
            class="text-white-50"
            :text="$t('components.attachment.AttachmentPreview.rotateAnticlockwise')"
            @click="rotateAntiClockwise"
          />
          <IconButton
            v-if="!isVideo(attachment)"
            data-ref="rotate-clockwise"
            icon="redo"
            icon-size="lg"
            :disabled="loading"
            class="text-white-50"
            :text="$t('components.attachment.AttachmentPreview.rotateClockwise')"
            @click="rotateClockwise"
          />
          <IconButton
            v-if="attachment.id"
            data-ref="download"
            icon="arrow-to-bottom"
            icon-size="lg"
            class="text-white-50"
            :text="$t('views.work-orders.WorkOrderDisplay.files.screenReader.buttonDownload')"
            @click="downloadFile"
          />
        </div>
        <IconButton
          v-if="canDelete && attachment && attachment.can_delete"
          data-ref="delete"
          icon="trash"
          class="text-white-50"
          :text="$t('components.attachment.AttachmentPreview.deleteAttachment')"
          @click="$emit('delete-attachment')"
        />
        <div v-else></div>
      </div>
    </template>
    <div
      v-if="isImage(attachment)"
      class="d-flex justify-content-center"
    >
      <div
        class="image-container"
        :style="`transform: scale(${zoom})`"
      >
        <img
          :style="`transform: rotate(${rotation}deg)`"
          :src="attachment.url"
          :alt="attachment.name"
          class="centered-image"
        />
      </div>
    </div>

    <div v-else-if="isPDF(attachment)">
      <div v-if="errors.length" class="text-white text-center mt-5">
        <b-alert v-for="(error, index) in errors" :key="index" variant="danger" show>
          <font-awesome-icon :icon="['far', 'exclamation-triangle']" class="mr-1" />&nbsp;<!--
          --><span :key="JSON.stringify(error)">{{ error }}</span>
        </b-alert>
      </div>

      <div v-else-if="loading" class="text-white d-flex justify-content-center mt-5">
        <b-spinner label="loading" class="mr-2" variant="light" /> {{ $t('common.forms.loading') }}
      </div>

      <div class="pdf-pages mx-auto" :style="{ width: 100 * zoom + '%'}">
        <VuePDF
          v-for="page in pdfPages"
          ref="pdfViewer"
          :key="page"
          :pdf="pdf"
          :page="page"
          :fit-parent="true"
          :rotation="rotation"
          class="pdf-page"
          @loaded="pdfLoaded"
        />
      </div>
    </div>

    <div v-else-if="isPlayableVideo(attachment)" class="centered-video">
      <b-embed type="video" controls autoplay>
        <source :src="attachment.url" :alt="attachment.name" :type="attachment.mime_type">
      </b-embed>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BSpinner, BAlert, BEmbed } from 'bootstrap-vue';
import IconButton from '@/ux/IconButton.vue';
import isImage from '@/util/isImage.js';
import isPDF from '@/util/isPDF.js';
import isVideo from '@/util/isVideo.js';
import isPlayableVideo from '@/util/isPlayableVideo';
import { VuePDF, usePDF } from '@tato30/vue-pdf'

export default {
  inject: ["mq"],
  components: {
    BModal,
    BSpinner,
    IconButton,
    BAlert,
    BEmbed,
    VuePDF,
  },
  props: {
    attachment: {
      type: [File, Object],
      default: null,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    staticModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      zoom: 1,
      rotation: 0,
      errors: [],
      loading: true,
      pdf: null,
      pdfPages: null,
    };
  },
  async mounted() {
    if (this.isImage(this.attachment)) {
      this.loading = false;
      this.calculateZoom();
      this.$refs.viewModal.show();
    } else if (this.isPDF(this.attachment)) {
      await this.getPdf();
      this.calculateZoom();
      this.$refs.viewModal.show();
    } else if (this.isPlayableVideo(this.attachment)) {
      this.$refs.viewModal.show();
    } else if (this.attachment.id) {
      window.open(this.attachment.url);
      this.$emit('close');
    } else {
      this.$emit('close');
    }
  },
  methods: {
    isImage,
    isPDF,
    isVideo,
    isPlayableVideo,
    calculateZoom() {
      if (this.mq.current === 'sm') {
        this.zoom = 0.6;
      } else if (this.mq.current === 'md') {
        this.zoom = 0.7;
      } else if (this.mq.current === 'lg') {
        this.zoom = 0.9;
      } else if (this.mq.current === 'xl') {
        this.zoom = 1.0;
      }
    },
    async getPdf() {
      const { pdf, pages } = usePDF(this.attachment.url);
      this.pdf = pdf;
      this.pdfPages = pages;
    },
    rotateClockwise() {
      if (this.rotation === 270) {
        this.rotation = 0;
      } else {
        this.rotation += 90;
      }
      this.reload();
    },
    rotateAntiClockwise() {
      if (this.rotation === 0) {
        this.rotation = 270;
      } else {
        this.rotation -= 90;
      }
      this.reload();
    },
    downloadFile() {
      window.open(this.attachment.url);
      this.$emit('close');
    },
    zoomIn() {
      if (this.zoom >= 2) {
        return;
      }
      this.zoom += 0.1;
      this.reload();
    },
    zoomOut() {
      if (this.zoom <= 0.2) {
        return;
      }
      this.zoom -= 0.1;
      this.reload();
    },
    reload() {
      if (this.isPDF(this.attachment)) {
        this.$refs.pdfViewer.forEach((page) => {
          page.reload();
        });
      }
    },
    pdfLoaded() {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.full-screen-modal img {
  width: 100%;
}
.image-container {
  display: inline-block;
}
.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 1rem;
}
.centered-video {
  width: 90%;
  margin: 0 auto;
  height: 90vh;
  .embed-responsive {
    max-height: 100%;
    max-width: 100%;
    video {
      width: auto;
      right: 0;
      margin: 0 auto;
    }
  }
}
</style>

<style lang="scss">
.pdf-pages {
  background-color: #ccc;

  .pdf-page {
    &:last-child {
      padding-bottom: 1rem;
    }

    canvas {
      margin: 0 auto;
      padding: 1rem 1rem 0 1rem;
      box-shadow: $shadow;
    }
  }
}
</style>
