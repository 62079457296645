import axios from 'axios';
import JsonApiResponseConverter from 'json-api-response-converter';

class GetAPI {
  static async get(route, config) {
    const params = {};

    Object.keys(config.fields).forEach((value) => {
      params[`fields[${value}]`] = config.fields[value].join(',');
    });

    Object.keys(config.meta).forEach((key) => {
      params[`meta[${key}]`] = config.meta[key];
    });

    if (config.relationships.length) {
      params.include = config.relationships.join(',');
    }

    const response = await axios({
      method: 'GET',
      url: `v2/${ route }/${ config.id}`,
      responseType: 'json',
      params,
    });

    return {
      data: new JsonApiResponseConverter(response.data).formattedResponse
    }
  }
}

export default GetAPI;
