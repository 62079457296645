<template>
  <div class="navigation" :class="{ expanded: expanded }">
    <div class="menu-container">
      <div class="logo d-none d-lg-block">
        <router-link :to="{ name: 'DashboardDisplay' }">
          <img v-if="logoUrl" :src="logoUrl" :alt="$t('common.logoAltText')" />
        </router-link>
      </div>

      <div class="mobile-header d-lg-none">
        <ToolbarProfile
          v-if="user"
          :hide-account-menu="true"
          :name="user.name"
          :role="user.role ? user.role.name : ''"
        />
        <button class="" @click="onCloseClick">
          <font-awesome-icon :icon="['far', 'times']" />&nbsp;<!--
          --><span class="sr-only">{{ $t('components.app.Navigation.closeNavigation') }}</span>
        </button>
      </div>

      <div v-if="loading" class="text-center mt-4">
        <b-spinner label="Loading Navigation" variant="primary" />
      </div>

      <b-alert
        v-if="globalErrors.length"
        variant="danger"
        show
        dismissible
        class="position-fixed fixed-bottom m-1 rounded-1"
        @dismissed="globalErrors = []"
      >
        <div v-for="(error, k) in globalErrors" :key="k">
          <span :key="error">{{ error }}</span>
        </div>
      </b-alert>

      <transition name="fade">
        <b-nav v-show="!loading" vertical>
          <b-nav-text v-for="(link, index) in allowedNavigationItems" :key="'links-' + index" tag="li" class="d-flex flex-column">
            <div class="nav-item d-flex align-items-stretch">
              <b-button
                v-if="link.to.indexOf('work-order/create') >= 0"
                variant="none"
                class="flex-fill nav-link text-left"
                @click="onWorkOrderCreateClick"
              >
                <font-awesome-icon :icon="['far', link.icon]" />
                <span :key="link.text">{{ link.text }}</span>
              </b-button>

              <b-button
                v-else-if="link.to.indexOf('asset-management/scan') >= 0"
                variant="none"
                class="flex-fill nav-link text-left"
                @click="onAssetScanClick"
              >
                <font-awesome-icon :icon="['far', link.icon]" />
                <span :key="link.text">{{ link.text }}</span>
              </b-button>

              <template v-else>
                <a v-if="isExternal(link)" :href="link.to" :target="isNewTab(link) ? '_blank' : ''" class="nav-link flex-fill">
                  <font-awesome-icon :icon="['far', link.icon]" />
                  <span :key="link.text">{{ link.text }}</span>
                </a>

                <router-link v-else class="nav-link flex-fill" :to="link.to">
                  <font-awesome-icon :icon="['far', link.icon]" />
                  <span :key="link.text">{{ link.text }}</span>
                </router-link>

                <b-button
                  v-if="link.children"
                  v-b-toggle="`link-group-${index}`"
                  class="toggle-button"
                  @click.prevent
                >
                  <font-awesome-icon class="collapsed" :icon="['far', 'plus-square']" size="sm" />
                  <font-awesome-icon class="expanded" :icon="['far', 'minus-square']" size="sm" />
                  <span class="collapsed sr-only">
                    {{ $t('components.app.Navigation.collapsedInfo') }}
                  </span>
                  <span class="expanded sr-only">
                    {{ $t('components.app.Navigation.expandedInfo') }}
                  </span>
                </b-button>
              </template>
            </div>
            <b-collapse v-if="link.children" :id="`link-group-${index}`" class="sub-links">
              <b-nav vertical>
                <b-nav-item
                  v-for="(childLink, childIndex) in link.children || []"
                  :key="`child-links-${index}-${childIndex}`"
                  :to="!isExternal(childLink) ? childLink.to : ''"
                  :href="isExternal(childLink) ? childLink.to : ''"
                  :target="isNewTab(childLink) ? '_blank' : ''"
                >
                  <span :key="childLink.text">{{ childLink.text }}</span>
                </b-nav-item>
              </b-nav>
            </b-collapse>
          </b-nav-text>

          <b-nav-item
            v-for="(link, index) in profileLinks"
            :key="'profile-links' + index"
            :to="{ name: link.to }"
          >
            <font-awesome-icon :icon="['far', link.icon]" />
            <span :key="link.text">{{ link.text }}</span>
          </b-nav-item>

          <b-nav-item v-if="isImpersonating" @click="stopImpersonating">
            <font-awesome-icon :icon="['far', 'exchange']" />
            <span>{{ $t('components.app.Navigation.exitSwitchUser') }}</span>
          </b-nav-item>
        </b-nav>
      </transition>
    </div>

    <div ref="overlay" class="menu-overlay" @click="onCloseClick" />
  </div>
</template>

<script>
import {BAlert, BNav, BNavItem, BSpinner, BCollapse, BButton, VBToggle, BNavText} from 'bootstrap-vue';
import { mapState, mapGetters } from 'vuex';
import ToolbarProfile from '@/components/user/ToolbarProfile.vue';
import NavigationAPI from '@/services/NavigationAPI.js';
import types from '@/store/types/authorization.js';
import getAPIErrors from '@/util/getAPIErrors.js';
import errorTypes from '@/store/types/error.js';
import DisplaysLogo from '@/mixins/DisplaysLogo.js';

export default {
  name: 'Navigation',
  components: {
    BAlert,
    BNav,
    BNavItem,
    BNavText,
    BSpinner,
    BCollapse,
    BButton,
    ToolbarProfile,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [DisplaysLogo],
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
      logoUrl: '',
      navigationItems: [],
      profileLinks: [],
      globalErrors: [],
    };
  },
  computed: {
    ...mapGetters(['hasPermissionOr', 'isImpersonating']),
    ...mapState({
      session_status: (state) => state.authorization.session_status,
      user: (state) => state.authorization.user,
    }),
    allowedNavigationItems() {
      const allowedNavigationItems = this.navigationItems.filter((item) =>
          this.hasPermissionOr(item.permission),
      );
      allowedNavigationItems.forEach((item) => {
        if (item.children) {
          item.children = item.children.filter((link) => this.hasPermissionOr(link.permission));
        }
        return item;
      });

      return allowedNavigationItems;
    },
  },
  watch: {
    async expanded(val) {
      // TODO: Remove direct manipulation of document.body
      if (val) {
        // this shows the mask (with opacity 0)
        document.body.classList.add('menu-expanded');

        await this.$nextTick();

        // this fades the mask in
        document.body.classList.add('menu-expanding');
      } else {
        // this fades the mask out
        document.body.classList.add('menu-collapsing');

        await this.$nextTick();

        // after the animation has completed we remove all related classes.
        // neutral state is hidden
        setTimeout(() => {
          document.body.classList.remove('menu-expanded');
          document.body.classList.remove('menu-expanding');
          document.body.classList.remove('menu-collapsing');
        }, 500);
      }
    },
  },
  created() {
    this.fetchLogo();
  },
  mounted() {
    document.body.appendChild(this.$refs.overlay);

    this.loadLookups();
  },
  methods: {
    async fetchLogo() {
      this.logoUrl = await this.logo;
    },
    isExternal(item) {
      return typeof item.to === 'string' && item.to.startsWith('http');
    },
    isNewTab(item) {
      return item?.new_tab && item.new_tab;
    },
    /**
     * @summary Handler for click on the close menu button.
     * @method
     */
    onCloseClick() {
      /**
       * @summary Fired when the close menu button is clicked
       * @event close
       */
      this.$emit('close');
    },

    async stopImpersonating() {
      try {
        await this.$store.dispatch(types.actions.IMPERSONATE_USER, {
          userId: null,
        });

        window.location = this.$router.resolve({ name: 'WorkOrders' }).href;
      } catch (e) {
        await this.$store.dispatch(
          errorTypes.actions.RAISE_ERROR,
          getAPIErrors(e, this.$t('views.users.UserDisplay.impersonateUserFailed')),
        );
      }
    },

    async loadLookups() {
      this.loading = true;

      try {
        await Promise.all([this.loadNavigation()]);
      } catch (e) {
        // eslint-disable no-empty-block
        // todo handle errors: refactor to centralise lookups
      } finally {
        this.loading = false;
      }
    },

    async loadNavigation() {
      const navigationSchemeId = this?.user?.role?.navigation_scheme_id;
      if (navigationSchemeId) {
        this.navigationItems = await NavigationAPI.getNavigationItems(navigationSchemeId);
      }
    },

    onWorkOrderCreateClick() {
      this.$globalEmit.emit('global-work-order-create-open', {});

      this.onCloseClick();
    },

    onAssetScanClick() {
      this.$globalEmit.emit('global-asset-scan-open', {});
      this.onCloseClick();
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  background: $dark;
  height: 100%;
  min-height: 100%;
  width: 250px;
  min-width: 250px;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: -100%;
  overflow-x: hidden;
  transition: 0.2s ease-in-out;
  text-align: left;

  &.expanded {
    left: 0;
  }

  .logo {
    display: none;
    padding: 0.75rem 1rem;

    img {
      width: 100%;
    }
  }

  .mobile-header {
    display: flex;
    align-items: center;
    padding: 0 0 0 1rem;
    height: 3.5rem;

    .toolbar-profile {
      flex: 1;
      text-align: left;
      color: #f8f9fb;
    }

    button {
      width: 3.5rem;
      height: 2rem;
      font-size: 1.5rem;
      padding: 0 1rem;
      color: #fff;
      background: transparent;
      border: none;
    }
  }

  ul {
    background: $dark;

    .navbar-text {
      padding: 0;
    }

    .nav-item {
      :deep(.nav-link) {
        color: #fff;
        padding: 1rem 0.5rem 1rem 1.5rem;
        font-size: 0.85rem;

        &.router-link-exact-active {
          &:hover {
            background: adjust-lightness($dark, 7.5%);

            + button {
              background: adjust-lightness($dark, 7.5%);
            }
          }
        }

        &:hover {
          background: adjust-lightness($dark, 2.5%);

          + button {
            background: adjust-lightness($dark, 2.5%);
          }
        }

        svg {
          width: 1rem;
          margin-right: 1.5rem;
        }
      }
    }

    .spacer {
      border-top: 1px solid #aaa;
      margin: 0.5rem 1.5rem;
    }
  }

  .menu-container {
    height: 100vh;
    background: $dark;
  }

  .toggle-button {
    background: none;
    border: none;
    border-radius: 0;
    width: 3rem;
    transition: none;
    text-align: left;
    color: #bbb;

    .collapsed {
      display: none;
    }
    .expanded {
      display: inline-block;
    }

    &.collapsed {
      .collapsed {
        display: inline-block;
      }
      .expanded {
        display: none;
      }
    }
  }

  .sub-links {
    .nav {
      margin: 0;
    }

    .nav-item {
      :deep(.nav-link) {
        color: #bbb;
        padding: 0.5rem 0 0.5rem 4rem;
        font-weight: normal;
        font-size: 0.825rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .navigation {
    position: static;
    height: auto;

    .menu-overlay {
      display: none;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
body {
  .menu-overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 10;
  }

  &.menu-expanding {
    .menu-overlay {
      opacity: 0.9;
    }
  }

  &.menu-expanded {
    .menu-overlay {
      display: block;
    }
  }

  &.menu-collapsing {
    .menu-overlay {
      opacity: 0;
    }
  }
  &.menu-collapsed {
    .menu-overlay {
      display: none;
    }
  }
}
</style>
