import types from '../../types/role.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_ROLES
   * @summary Updates the `roles` property.
   * @method
   * @param {Object} state Current state
   * @param {User[]} roles The loaded Roles array
   */
  [m.SET_ROLES](state, roles) {
    state.roles = roles;
  },

  [m.SET_ROLES_STATUS](state, status) {
    state.rolesStatus = status;
  },

  /**
   * @name m SET_ROLES_META
   * @summary Updates the `meta` property which holds the meta data for the `ROLES` collection
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_ROLES_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_ROLES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Currencies collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_ROLES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_ROLE
   * @summary Updates the `activeRole` and resets the
   * `activeRoleModifiedFields` & `activeRoleErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Role} role The new Active {@link Role}
   */
  [m.SET_ACTIVE_ROLE](state, role) {
    state.activeRole = role;
    state.activeRoleModifiedFields = !role || role.id ? {} : { ...role };
    state.activeRoleErrors = [];
  },

  /**
   * @name m SET_ACTIVE_ROLE_STATE
   * @summary Updates the `activeRoleState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeRoleState The current activeRoleState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_ROLE_STATE](state, activeRoleState) {
    state.activeRoleState = activeRoleState;
  },

  /**
   * @name m SET_ACTIVE_ROLE_ERRORS
   * @summary Updates the `activeRoleErrors` property which holds any
   * errors related to activeRole
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_ROLE_ERRORS](state, errors) {
    state.activeRoleErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_ROLE_VALID
   * @summary Updates the `activeRoleValid` property which holds
   * a boolean for the validation status of the `activeRole`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeRole` is valid.
   */
  [m.SET_ACTIVE_ROLE_VALID](state, valid) {
    state.activeRoleValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_ROLE_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeRoleModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeRole` object (i.e. it hasn't actually changed, or has been reverted back to it's
   * original) then we remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_ROLE_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeRole && state.activeRole[field] === value) {
      delete state.activeRoleModifiedFields[field];
    } else {
      state.activeRoleModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_ROLE_MODIFIED_FIELDS
   * @summary Empties the `activeRoleModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Role Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_ROLE_MODIFIED_FIELDS](state) {
    state.activeRoleModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_ROLE
   * @summary Removes the given role from the `roles` array. This is used to prevent a
   * full reload being needed after deleting a Role.
   * @method
   * @param {Object} state Current state
   * @param {object} role The Role to remove.
   */
  [m.REMOVE_LOCAL_ROLE](state, role) {
    const index = state.roles.findIndex((u) => u.id === role.id);

    if (index >= 0) {
      state.roles.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_ROLE
   * @summary Adds the given role to the `roles` array. This is used to prevent a
   * full reload being needed after adding a Role.
   * @method
   * @param {Object} state Current state
   * @param {object} role The Role to add.
   */
  [m.ADD_LOCAL_ROLE](state, role) {
    state.roles.push(role);
  },

  /**
   * @name m UPDATE_LOCAL_ROLE
   * @summary Updates the related role object in the `roles` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} role The Role to update.
   */
  [m.UPDATE_LOCAL_ROLE](state, role) {
    const localRole = state.roles.find((c) => c.id === role.id);

    if (localRole) {
      Object.assign(localRole, role);
    }
  },
};
