<template>
  <div>
    <b-modal
      v-bind="$attrs"
      ref="modal"
      dialog-class="expansive-modal"
      scrollable
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :no-enforce-focus="true"
    >
      <template v-slot:modal-header>
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="w-25">
          </div>

          <div class="flex-fill text-center text-bold">
            <slot name="title">
            <span :key="$t('views.asset.scan.title')">
              {{ $t('views.asset.scan.title') }}
            </span>
            </slot>
          </div>

          <div class="w-25 text-right">
            <b-button variant="none" @click="hide">
              <font-awesome-icon :icon="['far', 'times']"></font-awesome-icon>
            </b-button>
          </div>
        </div>
      </template>

      <div class="p-4">
        <FormGroup
          v-slot="slotProps"
          :label="$t('views.asset.fields.label')"
          :description="$t('views.asset.fields.labelDescription')"
          :field="v$.label"
        >
          <b-input-group>
            <b-form-input
              v-bind="slotProps"
              v-model="v$.label.$model"
              :disabled="loading"
              name="label"
              @keydown.enter="onEnterPress"
            />
            <b-input-group-append>
              <b-button variant="primary" class="input-group-button px-3" @click="showBarcodeScanner">
                <font-awesome-icon :icon="['far', 'barcode-read']" />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <BarcodeScanner
            ref="barcodeScanner"
            @confirmed="onBarcodeScannerConfirmed"
          />
        </FormGroup>

        <SpinButton
          :loading="loading"
          :loading-msg="$t('common.searching')"
          :disabled="v$.$invalid"
          variant="primary"
          @click="search"
        >
          <span v-if="!searched" :key="$t('common.search')">{{ $t('common.search') }}</span>
          <span v-else :key="$t('views.asset.scan.searchAgain')">{{ $t('views.asset.scan.searchAgain') }}</span>
        </SpinButton>

        <div v-if="searched" class="mt-4">
          <PrimeSkeleton v-show="loading" height="10rem" />

          <div v-show="!loading">
            <div v-if="!searchResults.length">
              <div :key="$t('views.asset.scan.noResults')" class="p-3 bg-light rounded">
                {{ $t('views.asset.scan.noResults') }}
              </div>
              <b-button v-if="allowCreateAsset" variant="outline-primary" class="mt-2" @click="createAsset">
                <span :key="$t('views.asset.scan.createAsset')">
                  {{ $t('views.asset.scan.createAsset') }}
                </span>
              </b-button>
            </div>
            <div v-else>
              <div class="font-weight-bold mb-2">
                {{ $t('views.asset.scan.assetFound') }}
              </div>
              <SingleAsset
                v-for="asset in searchResults"
                :key="asset.id"
                :asset="asset"
                :can-select="false"
                :show-thumbnail="false"
                class="single-asset"
                @onAssetClick="onAssetClick(asset)"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import BarcodeScanner from "@/ux/BarcodeScanner.vue";
import FormGroup from "@/ux/form/FormGroup.vue";
import {BModal, BButton, BFormInput, BInputGroup} from "bootstrap-vue";
import SpinButton from "@/ux/SpinButton.vue";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import PrimeSkeleton from "primevue/skeleton/Skeleton.vue";
import SingleAsset from "@/components/workOrder/assetSelector/SingleAsset.vue";
import {useAssetStore} from "@/stores/asset";
import IndexConfig from "@/services/v2/IndexConfig";
import IndexAPI from "@/services/v2/IndexAPI";
import {mapState} from "pinia";

export default {
  name: 'AssetScanModal',

  setup() {
    return { v$: useVuelidate() };
  },

  components: {
    SingleAsset,
    PrimeSkeleton,
    BInputGroup,
    BButton,
    BFormInput,
    BModal,
    FormGroup,
    BarcodeScanner,
    SpinButton,
  },

  props: {
    allowCreateAsset: {
      type: Boolean,
      default: true,
    },
    redirectToAsset: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['asset-clicked'],

  data() {
    return {
      label: null,
      loading: false,
      searchResults: [],
      searched: false,
    };
  },

  computed: {
    ...mapState(useAssetStore, ['phantomAsset']),

    createAssetRoute() {
      return {
        name: 'AssetsHome',
        query: {
          create: true,
        },
      }
    }
  },

  validations() {
    return {
      label: {required},
    };
  },

  methods: {
    reset() {
      this.label = null;
      this.loading = false;
      this.searchResults = [];
      this.searched = false;
      this.v$.$reset();
    },

    async show() {
      this.reset();
      await this.$refs.modal.show();
    },

    hide() {
      this.$refs.modal.hide();
    },

    showBarcodeScanner() {
      this.$refs.barcodeScanner.show();
    },

    async onBarcodeScannerConfirmed(barcode) {
      this.label = barcode;
      await this.search();
    },

    async search() {
      this.searched = true;
      this.loading = true;

      const response = await IndexAPI.fetch('assets',
        (new IndexConfig())
          .setFields({
            'assets': ['label', 'assetModel', 'warranty_labour', 'warranty_parts', 'serial_number', 'maintainableEntity', 'assetStatus', 'conditionGrade'],
            'asset-statuses': ['status', 'color'],
            'condition-grades': ['name', 'color'],
            'asset-models': ['name', 'media', 'assetType'],
            'asset-types': ['name', 'assetCategory'],
            'asset-categories': ['name'],
            'media': ['collection_name', 'url', 'file_exists'],
          })
          .setIncludes(['maintainableEntity', 'assetStatus', 'conditionGrade', 'assetModel.assetType.assetCategory', 'assetModel.media'])
          .setFilters({
            label: this.label,
          })
          .setPageSize(1)
          .setPage(1)
      );

      this.searchResults = response.data;
      this.searchResults = useAssetStore().addNames(this.searchResults);
      this.searchResults = useAssetStore().addThumbnails(this.searchResults);

      this.loading = false;
    },

    onEnterPress() {
      if (!this.v$.$invalid) {
        this.search();
      }
    },

    onAssetClick(asset) {
      this.hide();

      if (!this.redirectToAsset) {
        return this.$emit('asset-clicked', asset);
      }

      this.$router.push({
        name: 'AssetDisplay',
        params: {
          id: asset.id,
        },
      });
    },

    createAsset() {
      this.loading = true;

      useAssetStore().$patch({
        phantomAsset: {label: this.label}
      });

      this.$router.push({
        name: 'AssetsHome',
        query: {
          create: true,
        },
      });

      this.hide();
    },
  }

};
</script>

<style lang="scss" scoped>
.single-asset {
  cursor: pointer;
  :deep(label) {
    cursor: pointer;
  }
}
.input-group-button {
  cursor: pointer;
  border-radius: 0 0.25rem 0.25rem 0;
}
</style>
